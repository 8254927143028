import React from "react";
import { Grid } from "@material-ui/core";
import Page1 from './graphic_pages/Page1';
import Page2 from "./graphic_pages/Page2";
import Page3 from "./graphic_pages/Page3";

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import GraphicNavBar from "./GraphicNavBar";
import { makeStyles } from '@material-ui/styles';


import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%'
  },
  wrapper: {
minHeight: '400px',
display: 'flex',
flexWrap: 'wrap',
justifyContent: 'center',
width: '100%',
'@media (max-width: 600px)':{
  display: 'block',
}
  },
  
  nav: {
    width: '100%',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    
    
    
  },
  paper: { width: '80%',
  
  
    
  },
  grafika: {width: '100%',
  margin: '20px',
display: 'flex',
justifyContent: 'center'}

}));








const Graphic = () => {
  
  const classes = useStyles();

  return (
    <Router>
      <Container maxWidth="lg" style={{position: "relative", top:10}}>
        <Grid container className={classes.container} direction="column">
          <Grid className={classes.nav} item>
            <GraphicNavBar />
          </Grid>
          <Grid item className={classes.wrapper}>
            <Switch>
              <Route path="/grafika">
                <Page1 />
              </Route>
              <Route path="/page2" component={Page2} />
              <Route path="/page3" component={Page3} />
              
            </Switch>
          </Grid>
        </Grid>
      </Container>
    </Router>
  );
}

export default Graphic;
