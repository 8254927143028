import React from 'react'
import {  FaFacebook, FaLinkedin } from 'react-icons/fa'


export const links = [
  {
    id: 1,
    url: "/",
    text: "About me",
  },
  {
    id: 2,
    url: "/grafika",
    text: "Graphic design",
  },
  {
    id: 3,
    url: "/webdev",
    text: "Web Development",
  },
  
];

export const social = [
  {
    id: 1,
    url: "https://www.facebook.com/zbigniew.wyzinski",
    icon: <FaFacebook style={{ fontSize: "1.2rem" }} />,
  },
  {
    id: 2,
    url: "https://www.linkedin.com/in/zbigniew-wyzinski-16871b180/",
    icon: <FaLinkedin style={{ fontSize: "1.2rem" }} />,
  },
];
