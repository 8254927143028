import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import img1 from "./assets/hunterian/Events page PNG moodboard/PC 1920 x 1080 px Events_compressed.jpg";
import img2 from "./assets/hunterian/Events page PNG moodboard/Basilica Aemilia_compressed.jpg";
import img3 from "./assets/hunterian/Events page PNG moodboard/Basilica of Maxentius_compressed.jpg";
import img4 from "./assets/hunterian/Events page PNG moodboard/Colosseum_compressed.jpg";
import img5 from "./assets/hunterian/Events page PNG moodboard/Curia_compressed.jpg";
import img6 from "./assets/hunterian/Events page PNG moodboard/Palatine Hill_compressed.jpg";
import img7 from "./assets/hunterian/Events page PNG moodboard/Romulus Temple_compressed.jpg";
import img8 from "./assets/hunterian/Events page PNG moodboard/Temple of Antoninus and Faustina_compressed.jpg";
import img9 from "./assets/hunterian/Events page PNG moodboard/Temple of Castor and Pollux_compressed.jpg";
import img10 from "./assets/hunterian/Events page PNG moodboard/PC 1920 x 1080 px Events kopia_compressed.jpg";
import img11 from "./assets/hunterian/Events page PNG moodboard/About Antonine Wall board_compressed.jpg";
import img12 from "./assets/hunterian/Events page PNG moodboard/About Exhibit_compressed.jpg";

const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
  },
  text: {
    fontFamily: "Oswald, serif",
    fontSize: "1rem",
    margin: "10px 0px",
    padding: 5,
    borderBottom: "1px solid hsl(360, 67%, 44%)",
    "@media (max-width:600px)": {
      fontSize: "0.8rem",
    },
  },
  heading: {
    fontSize: "1rem",
    fontFamily: "Cinzel, serif",

    "@media (max-width:800px)": {
      fontSize: "0.8rem",
    },
    "@media (max-width:600px)": {
      fontSize: "0.6rem",
    },
  },
}));

const EventsAccordion = () => {

    const classes = useStyles();

    return (
      <>
        <Grid container direction="row" spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Events page - Roman Empire
                </Typography>
              </Grid>
              <Grid item>
                <img className={classes.img} src={img1} alt="Events" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Events page - Roman Empire - Modal 1
                </Typography>
              </Grid>
              <Grid item className={classes.imgBorder}>
                <img className={classes.img} src={img2} alt="Events" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Events page - Roman Empire - Modal 2
                </Typography>
              </Grid>
              <Grid item className={classes.imgBorder}>
                <img className={classes.img} src={img3} alt="Events" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Events page - Roman Empire - Modal 3
                </Typography>
              </Grid>
              <Grid item className={classes.imgBorder}>
                <img className={classes.img} src={img4} alt="Events" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Events page - Roman Empire - Modal 4
                </Typography>
              </Grid>
              <Grid item className={classes.imgBorder}>
                <img className={classes.img} src={img5} alt="Events" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Events page - Roman Empire - Modal 5
                </Typography>
              </Grid>
              <Grid item className={classes.imgBorder}>
                <img className={classes.img} src={img6} alt="Events" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Events page - Roman Empire - Modal 6
                </Typography>
              </Grid>
              <Grid item className={classes.imgBorder}>
                <img className={classes.img} src={img7} alt="Events" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Events page - Roman Empire - Modal 7
                </Typography>
              </Grid>
              <Grid item className={classes.imgBorder}>
                <img className={classes.img} src={img8} alt="Events" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Events page - Roman Empire - Modal 8
                </Typography>
              </Grid>
              <Grid item className={classes.imgBorder}>
                <img className={classes.img} src={img9} alt="Events" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            
                <Typography className={classes.text}>
                  Events page - Antonine wall
                </Typography>
              
              
                <img className={classes.img} src={img10} alt="Events" />
              
            
          </Grid>
          <Grid item xs={8}>
            
                <Typography className={classes.text}>
                  Events page - Antonine wall - About Antonine wall
                </Typography>
              
              
                <img className={classes.img} src={img11} alt="Events" />
              
          </Grid>
          <Grid item xs={8}>
            
                <Typography className={classes.text}>
                  Events page - Antonine wall - About Exhibit
                </Typography>
              
              
                <img className={classes.img} src={img12} alt="Events" />
              
            
          </Grid>
        </Grid>
      </>
    );
}

export default EventsAccordion;