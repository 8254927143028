import React from "react";
import { Container, Grid } from "@material-ui/core";
import Card1 from "./web_dev/Card1";
import Card2 from "./web_dev/Card2";
import Card3 from "./web_dev/Card3";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
}));

const WebDev = () => {

  const classes = useStyles();

  return (
    <>
      <Container maxWidth="lg" style={{ position: "relative", top: 10 }}>
        <Grid container className={classes.root}>
          <Grid item xs={12} sm={6} lg={4}>
            <Card1 />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <Card2 />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Card3 />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default WebDev;
