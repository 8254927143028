import React from "react";
import image1 from "./assets/mars/mars_full.jpg";
import { withStyles, makeStyles } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import img1 from "./assets/mars/mars_full.jpg";
import img2 from "./assets/mars/mars_full_camera.jpg";
import img3 from "./assets/mars/mars_full_rover.jpg";
import img4 from "./assets/mars/mars_mobile.jpg";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontFamily: "Oswald, serif",
    fontSize: "0.8rem",
  },
  body: {
    fontFamily: "Oswald, serif",
    fontSize: "0.8rem",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
      fontFamily: "Oswald, serif",
      fontSize: "0.8rem",
    },
  },
}))(TableRow);

function createData(rover, available) {
  return { rover, available };
}

const rows = [
  createData("Opportunity", "sol 1-222"),
  createData("Curiosity", "sol 222-2804"),
  createData("Spirit", "sol 1-218"),
];



function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "350px",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  paper: {
    position: "absolute",
    width: 800,
    height: 600,
    overflowY: "scroll",

    background: "hsl(210, 31%, 80%)",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "@media (max-width: 800px)": {
      width: 600,
      height: 600,
      overflowY: "scroll",
    },
    "@media (max-width: 600px)": { width: 400, height: 400 },
    "@media (max-width: 360px)": { width: 275, height: 400 },
  },
  header: {
    fontFamily: "Cinzel, serif",
    fontSize: "0.8rem",
    fontWeight: "bold",

    color: "#222",
    marginBottom: 0,
    "@media (max-width:600px)": {
      marginLeft: 10,
    },
    "@media (max-width:460px)": {
      fontSize: "0.6rem",
    },
    "@media (max-width:360px)": {
      marginLeft: 0,
    },
    "@media (max-width:290px)": {
      fontSize: "0.6rem",
    },
  },
  avatar: {
    "@media (min-width:600px)": {
      display: "none",
    },
    "@media (max-width:360px)": {
      display: "none",
    },
  },
  button: {
    fontFamily: "Cinzel, serif",
    fontSize: "0.6rem",
    fontWeight: "bold",
    marginTop: 10,
    "@media (max-width:600px)": {
      marginTop: 0,
    },
    "@media (max-width:460px)": {
      display: "none",
    },
  },
  buttonMobile: {
    fontFamily: "Cinzel, serif",

    fontWeight: "bold",
    background: "none",
    outline: "none",
    "&:focus": {
      outline: "none",
      color: "hsl(125, 67%, 44%)",
    },
    "@media (min-width:460px)": {
      display: "none",
    },
  },
  action: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignContent: "center",
    background: "hsl(210, 36%, 96%)",
    "@media (max-width:600px)": {
      flexDirection: "row",
    },
    "@media (max-width:360px)": {
      justifyContent: "space-between",
    },
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    borderBottom: "1px solid #222",
    "@media (max-width: 600px)": { display: "none" },
  },
  display: {
    display: "flex",
    justifyContent: "center",
    margin: "20px",
    "@media (max-width: 600px)": {
      margin: "10px",
    },
  },
  grid: {
    border: "1px solid black",
    background: "#fff",
    margin: 5,
    marginTop: 10,
  },
  img: {
    width: "100%",
  },
  modalHeader: {
    padding: "10px 0px",
    fontFamily: "Cinzel, serif",
    fontSize: "1rem",
    color: "#fff",
    "@media (max-width: 600px)": { fontSize: "0.6rem" },
  },
  paperHeader: {
    background: "hsl(205, 86%, 17%)",

    display: "flex",
    justifyContent: "center",
  },
  type: {
    justifyContent: "flex-end",
    alignItems: "center",
  },
  text: {
    fontFamily: "Oswald, serif",
    fontSize: "1rem",
    marginBottom: 10,
    "@media (max-width:600px)": {
      fontSize: "0.8rem",
    },
  },
  text2: {
    marginLeft: 20,
    fontFamily: "Oswald, serif",
    fontSize: "1rem",
    marginBottom: 10,
    "@media (max-width:600px)": {
      fontSize: "0.8rem",
    },
  },
  heading: {
    fontSize: "1.2rem",
    fontFamily: "Cinzel, serif",
    marginBottom: 10,

    "@media (max-width:800px)": {
      fontSize: "1rem",
    },
    "@media (max-width:600px)": {
      fontSize: "0.8rem",
    },
  },
  showMeButton: {
    padding: "5px 15px",
    borderRadius: 5,
    margin: 10,
    background: "rgba(187, 37, 37, 0.8)",
    border: "1px solid hsl(360, 67%, 44%)",
    color: "white",
    fontFamily: "Oswald, serif",
    fontSize: "0.8rem",
    "&:hover": {
      color: "white",
      background: "hsl(0, 100%, 41.76%)",
    },
    "@media (max-width:600px)": {
      fontSize: "0.6rem",
    },
  },
}));

const Card3 = () => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Grid container spacing={1} justify="center" alignContent="center">
        <Grid item xs={12}>
          <Paper elevation={3} className={classes.paperHeader}>
            <Grid container justifyContent="center">
              <Grid item>
                <Typography className={classes.modalHeader} variant="body2">
                  Mars project
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} className={classes.paperHeader}>
            <Typography className={classes.modalHeader} variant="body2">
              React / Bootstrap
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          className={classes.modalHeader}
          style={{ margin: "10px 0px" }}
        >
          <Typography>
            <a
              href="https://mars.webdev.smarthost.pl/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.showMeButton}
            >
              Show me this project
            </a>
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                className={classes.heading}
                style={{ marginBottom: "0px" }}
              >
                About project
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.heading}>
                    Mars project
                  </Typography>
                  <Typography className={classes.heading}>
                    Purpose of the website:
                  </Typography>
                  <Typography className={classes.text}>
                    Website for enthusiasts of traveling to the red planet. The
                    website was created for enthusiasts of extraterrestrial
                    photography. Its task is to facilitate access to data from
                    NASA space missions to Mars. Intuitive operation allows you
                    to quickly find photos from Mars.
                  </Typography>
                  <Typography className={classes.heading}>
                    End user profile:
                  </Typography>
                  <Typography className={classes.text}>
                    Wide age group, the site is aimed mainly at space and space
                    photography enthusiasts.
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={11}>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography
                className={classes.heading}
                style={{ marginBottom: "0px" }}
              >
                How it works:
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                  <img className={classes.img} src={img1} alt="mars project" />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    className={classes.text}
                    style={{ color: "hsl(360, 67%, 44%)" }}
                  >
                    Choosing a Martian day (Sol) options:
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>
                            <Typography
                              className={classes.text}
                              style={{ color: "#fff" }}
                            >
                              Rover
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Typography
                              className={classes.text}
                              style={{ color: "#fff" }}
                            >
                              Sol (Martian day)
                            </Typography>
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <StyledTableRow key={row.rover}>
                            <StyledTableCell component="th" scope="row">
                              <Typography className={classes.text}>
                                {row.rover}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <Typography className={classes.text}>
                                {row.available}
                              </Typography>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    className={classes.text}
                    style={{ color: "hsl(360, 67%, 44%)" }}
                  >
                    Choosing a rover options:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text2}>Curiosity</Typography>
                  <Typography className={classes.text2}>Opportunity</Typography>
                  <Typography className={classes.text2}>Spirit</Typography>
                </Grid>
                <Grid item xs={12}>
                  <img className={classes.img} src={img3} alt="mars project" />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    className={classes.text}
                    style={{ color: "hsl(360, 67%, 44%)" }}
                  >
                    Choosing a camera options:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text2}>
                    Front Hazard Avoidance Camera
                  </Typography>
                  <Typography className={classes.text2}>
                    Rear Hazard Avoidance Camera
                  </Typography>
                  <Typography className={classes.text2}>Mast Camera</Typography>
                  <Typography className={classes.text2}>
                    Chemistry and Camera Complex
                  </Typography>
                  <Typography className={classes.text2}>
                    Mars Hand Lens Imager
                  </Typography>
                  <Typography className={classes.text2}>
                    Mars Descent Imager
                  </Typography>
                  <Typography className={classes.text2}>
                    Navigation Camera
                  </Typography>
                  <Typography className={classes.text2}>
                    Panoramic Camera
                  </Typography>
                  <Typography className={classes.text2}>
                    Miniature Thermal Emission Spectrometer - Mini - TES
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <img className={classes.img} src={img2} alt="mars project" />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    className={classes.text}
                    style={{ color: "hsl(360, 67%, 44%)" }}
                  >
                    Now hit get the data and results will follow as it is:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <img className={classes.img} src={img1} alt="mars project" />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    className={classes.text}
                    style={{ color: "hsl(360, 67%, 44%)" }}
                  >
                    This project is responsive and it will work on mobile device
                    too:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <img className={classes.img} src={img4} alt="mars project" />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <>
      <div className={classes.display}>
        <Card className={classes.root}>
          <CardMedia
            className={classes.media}
            image={image1}
            title="Mars project"
          />
          <CardActions disableSpacing className={classes.action}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                className={classes.avatar}
                alt="Mars project"
                src={image1}
              />
              <Typography className={classes.header}>Mars project</Typography>
            </div>

            <button
              className={classes.buttonMobile}
              type="button"
              onClick={handleOpen}
              style={{
                outline: "none",
                border: "none",
                "&:focus": {
                  outline: "none",
                  background: "hsl(125, 67%, 44%)",
                },
              }}
            >
              <AddToQueueIcon
                color="secondary"
                style={{
                  "&:focus": {
                    color: "hsl(125, 67%, 44%)",
                  },
                }}
              />
            </button>
            <Button
              className={classes.button}
              size="small"
              variant="contained"
              color="secondary"
              type="button"
              onClick={handleOpen}
            >
              Show more
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="Mars project"
              aria-describedby="Mars project"
            >
              {body}
            </Modal>
          </CardActions>
        </Card>
      </div>
    </>
  );
};

export default Card3;
