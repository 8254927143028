import React from 'react';
import Card7 from './Card7';
import Card8 from "./Card8";
import Card9 from "./Card9";
import Card10 from "./Card10";
import Card11 from "./Card11";
import Card12 from "./Card12";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
  },
}));

 const Page2 = () => {
   const classes = useStyles();

    return (
      <>
        <Grid container spacing={0} className={classes.root}>
          <Grid item xs={12} sm={6} lg={4}>
            <Card7 />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <Card8 />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <Card9 />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Card10 />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <Card11 />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Card12 />
          </Grid>
        </Grid>
      </>
    );
}

export default Page2;
