import React, { useState, useRef, useEffect } from 'react'
import Home from './components/Home'
import Graphic from "./components/Graphic";
import WebDev from "./components/WebDev";
import Contact from "./components/Contact";
import { FaBars } from 'react-icons/fa'
import { links, social } from './data'
import profil from './assets/profil.png'
import { makeStyles } from '@material-ui/styles'
import {BrowserRouter as Router, Switch, Route, NavLink} from 'react-router-dom'
import styled from 'styled-components';
import { Typography } from '@material-ui/core';




const useStyles = makeStyles((theme) => ({
  profil: {
    width: "120px",
    margin: "0 auto",

    "@media (max-width: 960px)": {
      width: "80px",
    },
    "@media (max-width: 800px)": {
      position: "relative",
    },
  },
  heading: {
    fontSize: "1rem",
    fontFamily: "Cinzel, serif",
    lineHeight: "1.5em",
    color: "hsl(205, 90%, 76%)",

    "@media (max-width:1280px)": {
      fontSize: "0.8rem",
    },
  },
  menu: {
    fontSize: "1rem",
    fontFamily: "Cinzel, serif",
    lineHeight: "1.5em",

    "@media (max-width:1280px)": {
      fontSize: "0.8rem",
    },
  },
  headingName: {
    fontSize: "1.2rem",
    fontFamily: "Cinzel, serif",
    lineHeight: "1.5em",
    color: "hsl(205, 90%, 88%)",

    "@media (max-width:1280px)": {
      fontSize: "1rem",
    },
    
  },
}));

const Text = styled.div`
display:flex;
flex-direction: column;
  text-align: left;
  margin-left:10px;
  @media (max-width: 800px) { text-align: center;

  }
  
`;

const Navbar = () => {

  const classes = useStyles();
  const [showLinks, setShowLinks] = useState(false);
  const linksContainerRef = useRef(null);
  const linksRef = useRef(null);

  useEffect(() => {
    const linksHeight = linksRef.current.getBoundingClientRect().height 
    if (showLinks) {
      linksContainerRef.current.style.height = `${linksHeight}px`
    }
    else {
      linksContainerRef.current.style.height = '0px'
    }
  }, [showLinks])

  return (
    <Router>
      <nav>
        <div className="nav-center">
          <div className="nav-header">
            <div className="profile_box">
              <div style={{ margin: "0 auto" }}>
                <img className={classes.profil} src={profil} alt="profil" />
              </div>
              <Text>
                <Typography className={classes.headingName}>
                  Zbigniew Wyziński
                </Typography>
                <Typography className={classes.heading}>
                  Junior React.js developer
                </Typography>
                <Typography className={classes.heading}>
                  Graphic designer
                </Typography>
              </Text>
            </div>
            <button
              className="nav-toggle"
              onClick={() => setShowLinks(!showLinks)}
            >
              <FaBars />
            </button>
          </div>

          <div className="links-container" ref={linksContainerRef}>
            <ul className="links" ref={linksRef}>
              {links.map(({ id, url, text }) => {
                return (
                  <li key={id} style={{ textAlign: "center" }}>
                    <NavLink to={url} onClick={() => setShowLinks(!showLinks)}>
                      <span className={classes.menu}>{text}</span>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ul className="social-icons">
              {social.map(({ id, url, icon }) => {
                return (
                  <li key={id}>
                    <a href={url}>{icon}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
      <div className="classes.container">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/grafika" component={Graphic} />
          <Route path="/webdev" component={WebDev} />
          <Route path="/kontakt" component={Contact} />
        </Switch>
      </div>
    </Router>
  );
}

export default Navbar
