import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import img1 from "../assets/math/Artboard_home.png";
import img2 from "../assets/math/action_plan_home.jpg";
import img3 from "../assets/math/ico_link.png";
import img4 from "../assets/math/ico_svg_anim.png";
import img5 from "../assets/math/ico_jquery2.png";
import img6 from "../assets/math/ico_css_anim.png";
import img7 from "../assets/math/ico_css_anim_footer.png";
import img8 from "../assets/math/ico_link2.png";
import img9 from "../assets/math/Artboard_content.png";
import img10 from "../assets/math/action_plan_content.jpg";
import img11 from "../assets/math/ico_jquery.png";
import img12 from "../assets/math/Artboard_parents.png";
import img13 from "../assets/math/action_plan_parents.jpg";
import img14 from "../assets/math/Artboard_math.png";
import img15 from "../assets/math/action_plan_math.jpg";
import img16 from "../assets/math/Artboard_numbers.png";
import img17 from "../assets/math/action_plan_numbers.jpg";


const useStyles = makeStyles((theme) => ({
  text: {
    marginLeft: 20,
    marginBottom: 10,
    fontFamily: "Oswald, serif",
    fontSize: "1rem",
    "@media (max-width:600px)": {
      fontSize: "0.8rem",
    },
  },
  text2: {
    marginLeft: 30,
    marginBottom: 10,
    fontFamily: "Oswald, serif",
    fontSize: "1rem",
    "@media (max-width:600px)": {
      fontSize: "0.8rem",
    },
  },
  caption: {
    margin: '10px 0px',
    fontFamily: "Oswald, serif",
    fontSize: "1rem",
    borderBottom: '1px solid hsl(360, 67%, 44%)',

    "@media (max-width:600px)": {
      fontSize: "0.8rem",
    },
  },
  heading: {
    fontSize: "1.2rem",
    fontFamily: "Cinzel, serif",
    marginBottom: 10,
    color: "hsl(360, 67%, 44%)",

    "@media (max-width:800px)": {
      fontSize: "1rem",
    },
    "@media (max-width:600px)": {
      fontSize: "0.8rem",
    },
  },
  img: {
    width: "100%",
  },
}));

const PlanningAccordion = () => {
  const classes = useStyles();
  return (
    <>
      <Grid direction="row" spacing={2} container>
        <Grid item xs={12}>
          <Typography className={classes.heading}>
            Website dimension:
          </Typography>

          <Typography className={classes.text}>1920 x 1080 px</Typography>
          <Typography className={classes.heading}>
            Graphic element by:
          </Typography>
          <Typography className={classes.text}>Julia Dreams</Typography>
          <Typography className={classes.heading}>
            Background for the website:
          </Typography>
          <Typography className={classes.text}>
            gradient colour from #4471B8 to #0074FF slider location 61.98%
            Linear -90 deg.
          </Typography>
          <Typography className={classes.heading}>
            Footer background:
          </Typography>
          <Typography className={classes.text}>
            transparent max-height: 200px / bottom-border height 20px / colour:
            #FFD517
          </Typography>
          <Typography className={classes.heading}>Fonts details:</Typography>
          <Typography className={classes.text}>For Heading / Logo :</Typography>
          <Typography className={classes.text2}>
            Crafty Girls - Regular - ( color black #000000 )
          </Typography>

          <Typography className={classes.text}>
            For Content text (instructions / calculation / hide and show text)
          </Typography>
          <Typography className={classes.text2}>
            Roboto Condensed - Regular - ( color black #000000 )
          </Typography>

          <Typography className={classes.caption}>
            Home page planning
          </Typography>
          <Grid item xs={12}>
            <img className={classes.img} src={img1} alt="Home page planning" />
          </Grid>
          <Typography className={classes.caption}>
            Home page action plan
          </Typography>
          <Grid item xs={12}>
            <img className={classes.img} src={img2} alt="Home page planning" />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.caption} style={{ border: "none" }}>
              Legend:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img3} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  Links to pages
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img4} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  SVG animation (bubbles going up)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img5} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  Javascript animation (fish move from right to left)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img6} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  CSS animation main content (on hover images scale up)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img7} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  CSS animation footer (on hover images rotate)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img8} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  Modal form show after click action
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.caption} style={{ marginTop: 20 }}>
              Content page planning
            </Typography>
            <img
              className={classes.img}
              src={img9}
              alt="Content page planning"
            />
          </Grid>
          <Typography className={classes.caption}>
            Content page action plan
          </Typography>
          <Grid item xs={12}>
            <img
              className={classes.img}
              src={img10}
              alt="Content page planning"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.caption} style={{ border: "none" }}>
              Legend:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img3} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  Links to pages
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img11} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  JQuery show/hide content on click action
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img6} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  CSS animation (on hover images rotate)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img7} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  CSS animation footer (on hover images rotate)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img8} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  Modal form show after click action
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.caption} style={{ marginTop: 20 }}>
              Parents page planning
            </Typography>
            <img
              className={classes.img}
              src={img12}
              alt="Parents page planning"
            />
          </Grid>
          <Typography className={classes.caption}>
            Parents page action plan
          </Typography>
          <Grid item xs={12}>
            <img
              className={classes.img}
              src={img13}
              alt="Parents page planning"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.caption} style={{ border: "none" }}>
              Legend:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img3} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  Links to pages
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img11} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  JQuery show/hide content on click action
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img6} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  CSS animation (on hover images rotate)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img7} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  CSS animation footer (on hover images rotate)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img8} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  Modal form show after click action
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img4} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  Bubbles animation
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.caption} style={{ marginTop: 20 }}>
              Math game page planning
            </Typography>
            <img
              className={classes.img}
              src={img14}
              alt="Math game page planning"
            />
          </Grid>
          <Typography className={classes.caption}>
            Math game page action plan
          </Typography>
          <Grid item xs={12}>
            <img
              className={classes.img}
              src={img15}
              alt="Math game page planning"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.caption} style={{ border: "none" }}>
              Legend:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img3} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  Links to pages
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img11} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  Check correct answer button
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img5} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  Input box for answer
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img6} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  CSS animation (on hover images scale up)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img7} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  CSS animation footer (on hover images rotate)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img8} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  Modal form show after click action
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.caption} style={{ marginTop: 20 }}>
              Numbers game page planning
            </Typography>
            <img
              className={classes.img}
              src={img16}
              alt="Numbers game page planning"
            />
          </Grid>
          <Typography className={classes.caption}>
            Numbers game page action plan
          </Typography>
          <Grid item xs={12}>
            <img
              className={classes.img}
              src={img17}
              alt="Numbers game page planning"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.caption} style={{ border: "none" }}>
              Legend:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img3} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  Links to pages
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img11} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  Javascript drag and drop script / Check correct answer button
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img6} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  CSS animation (on hover images scale up)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img7} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  CSS animation footer (on hover images rotate)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img8} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  Modal form show after click action
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1}>
                <img src={img4} alt="dot" />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.text2}>
                  Bubbles animation
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PlanningAccordion;
