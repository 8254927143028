import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';



const links = [
  {
    id: 1,
    url: "/grafika",
    title: "1",
  },
  {
    id: 2,
    url: "/page2",
    title: "2",
  },
  {
    id: 3,
    url: "/page3",
    title: "3",
  },
  
];

const NavBar = styled.nav`
  display: flex;
  
  align-items: center;
  justify-content: center;
`;
const LinkContainer = styled.ul`
  display: flex;
  
`;

const Link = styled.li`
  text-decoration: none;
  list-style-type: none;
  font-size: 0.8rem;
  

  &:hover {
    background: chocolate;
  }
  @media (max-width: 360px) {
    font-size: 0.6rem;
  }
`;

const LinkTitle = styled.div`
  margin: 0px 10px;
  padding: 2px;
  border-radius: 10%;
  color: white;
`;

const GraphicNavBar = () => {
    return (
      <>
        <NavBar>
          <LinkContainer>
            {links.map((link) => (
              <Link key={link.id}>
                <NavLink to={link.url} activeClassName="active" >
                  <LinkTitle>{link.title}</LinkTitle>
                </NavLink>
              </Link>
            ))}
          </LinkContainer>
        </NavBar>
      </>
    );
}

export default GraphicNavBar;