import React from 'react'
import Navbar from './Navbar'
import './App.css'
import Footer from './components/Footer'
import { Grid } from '@material-ui/core'
import CookieConsent from "react-cookie-consent";



function App() {
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} style={{}}>
          <CookieConsent
            location="top"
            buttonText="Accept"
            cookieName="CookieConsent"
            
            style={{ background: "hsl(360, 67%, 44%)"}}
            buttonStyle={{
              background: "hsl(125, 67%, 44%)",
              color: "black",
              fontSize: "13px",
              borderRadius: "25px",
            }}
            expires={7}
          >
            This website uses cookies to enhance the user experience.{" "}
          </CookieConsent>
        </Grid>

        <Grid item xs={12}>
          <Navbar />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 60 }}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
}

export default App
