import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  text: {
    marginLeft: 20,
    marginBottom: 10,
    fontFamily: "Oswald, serif",
    fontSize: "1rem",
    "@media (max-width:600px)": {
      fontSize: "0.8rem",
    },
  },
  heading: {
    fontSize: "1rem",
    fontFamily: "Cinzel, serif",
    marginBottom: 10,

    "@media (max-width:800px)": {
      fontSize: "0.8rem",
    },
    "@media (max-width:600px)": {
      fontSize: "0.6rem",
    },
  },
}));

const AboutProjectAccordion = () => {

    const classes = useStyles();
    return (
      <>
        <Grid direction="row" spacing={2} container>
          <Grid item xs={12}>
            <Typography
              className={classes.heading}
              style={{ color: "hsl(360, 67%, 44%)" }}
            >
              Curriculum area: Numeracy and mathematics
            </Typography>
            <Typography className={classes.heading}>
              Purpose of the website:
            </Typography>
            <Typography className={classes.text}>
              Educational - providing an attractive form of learning and form of
              checking knowledge for the end user. Providing additional support
              to the education sector in the United Kingdom. Providing
              additional tools for educating children in an off-school
              environment.Providing a practical educational tool for parents in
              an attractive and modern form that catches the child's attention.
            </Typography>
            <Typography className={classes.heading}>
              End user profile:
            </Typography>
            <Typography className={classes.text}>
              First class education 5 - 6 year and their parents / guardians the
              website must be visually appealing to a selected age group of
              children to attract their attention and must contain necessary
              information about its content in an accessible form for parents
              and guardians.
            </Typography>
          </Grid>
        </Grid>
      </>
    );
}

export default AboutProjectAccordion;