import React from 'react';
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import img1 from "./assets/hunterian/The Hunterian PNG moodboard/PC 1920 x 1080 px_compressed.jpg";
import img2 from "./assets/hunterian/The Hunterian PNG moodboard/William Hunter content scrollpage_compressed.jpg";
import img3 from "./assets/hunterian/The Hunterian PNG moodboard/PC 1920 x 1080 px kopia_compressed.jpg";
import img4 from "./assets/hunterian/The Hunterian PNG moodboard/Archaeology board_compressed.jpg";
import img5 from "./assets/hunterian/The Hunterian PNG moodboard/Bio-diversity board_compressed.jpg";
import img6 from "./assets/hunterian/The Hunterian PNG moodboard/Collectors board_compressed.jpg";
import img7 from "./assets/hunterian/The Hunterian PNG moodboard/Dinosaurs board_compressed.jpg";
import img8 from "./assets/hunterian/The Hunterian PNG moodboard/Exploration board_compressed.jpg";
import img9 from "./assets/hunterian/The Hunterian PNG moodboard/Minerals board_compressed.jpg";
import img10 from "./assets/hunterian/The Hunterian PNG moodboard/Scottish Fossils board_compressed.jpg";
import img11 from "./assets/hunterian/The Hunterian PNG moodboard/PC 1920 x 1080 px kopia 2_compressed.jpg";
import img12 from "./assets/hunterian/The Hunterian PNG moodboard/Lord Kelvin board_compressed.jpg";
import img13 from "./assets/hunterian/The Hunterian PNG moodboard/PC 1920 x 1080 px kopia 3_compressed.jpg";
import img14 from "./assets/hunterian/The Hunterian PNG moodboard/Healing Passion board_compressed.jpg";

const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
  },
  text: {
    fontFamily: "Oswald, serif",
    fontSize: "1rem",
    margin: "10px 0px",
    padding: 5,
    borderBottom: "1px solid hsl(360, 67%, 44%)",
    "@media (max-width:600px)": {
      fontSize: "0.8rem",
    },
  },
  heading: {
    fontSize: "1rem",
    fontFamily: "Cinzel, serif",

    "@media (max-width:800px)": {
      fontSize: "0.8rem",
    },
    "@media (max-width:600px)": {
      fontSize: "0.6rem",
    },
  },
}));

const TheHunterianAccordion = () => {
    const classes = useStyles();
    return (
      <>
        <Grid container direction="row" spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  The Hunterian page - William Hunter
                </Typography>
              </Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={img1}
                  alt="The Hunterian page - William Hunter"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  The Hunterian page - William Hunter - Board
                </Typography>
              </Grid>
              <Grid item className={classes.imgBorder}>
                <img
                  className={classes.img}
                  src={img2}
                  alt="The Hunterian page - William Hunter - Board"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  The Hunterian page - Exhibits
                </Typography>
              </Grid>
              <Grid item className={classes.imgBorder}>
                <img
                  className={classes.img}
                  src={img3}
                  alt="The Hunterian page - Exhibits"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  The Hunterian page - Exhibits - Archaeology
                </Typography>
              </Grid>
              <Grid item className={classes.imgBorder}>
                <img
                  className={classes.img}
                  src={img4}
                  alt="The Hunterian page - Exhibits - Archaeology"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  The Hunterian page - Exhibits - Bio Diversity
                </Typography>
              </Grid>
              <Grid item className={classes.imgBorder}>
                <img
                  className={classes.img}
                  src={img5}
                  alt="The Hunterian page - Exhibits - Bio Diversity"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  The Hunterian page - Exhibits - Collectors
                </Typography>
              </Grid>
              <Grid item className={classes.imgBorder}>
                <img
                  className={classes.img}
                  src={img6}
                  alt="The Hunterian page - Exhibits - Collectors"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  The Hunterian page - Exhibits - Dinosaurs
                </Typography>
              </Grid>
              <Grid item className={classes.imgBorder}>
                <img
                  className={classes.img}
                  src={img7}
                  alt="The Hunterian page - Exhibits - Dinosaurs"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  The Hunterian page - Exhibits - Exploration
                </Typography>
              </Grid>
              <Grid item className={classes.imgBorder}>
                <img
                  className={classes.img}
                  src={img8}
                  alt="The Hunterian page - Exhibits - Exploration"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  The Hunterian page - Exhibits - Minerals
                </Typography>
              </Grid>
              <Grid item className={classes.imgBorder}>
                <img
                  className={classes.img}
                  src={img9}
                  alt="The Hunterian page - Exhibits - Minerals"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  The Hunterian page - Exhibits - Scottish Fossils
                </Typography>
              </Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={img10}
                  alt="The Hunterian page - Exhibits - Scottish Fossils"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  The Hunterian page - Lord Kelvin
                </Typography>
              </Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={img11}
                  alt="The Hunterian page - Lord Kelvin"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  The Hunterian page - Lord Kelvin - Board
                </Typography>
              </Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={img12}
                  alt="The Hunterian page - Lord Kelvin - Board"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  The Hunterian page - Healing Passion
                </Typography>
              </Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={img13}
                  alt="The Hunterian page - Healing Passion"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  The Hunterian page - Healing Passion - Board
                </Typography>
              </Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={img14}
                  alt="The Hunterian page - Healing Passion Board"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
}

export default TheHunterianAccordion;