import React from "react";
import image1 from "./assets/glasgow_art/art_cathedral.png";
import { makeStyles } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import img1 from "./assets/glasgow_art/art_armadillo.png";
import img2 from "./assets/glasgow_art/art_cathedral.png";
import img3 from "./assets/glasgow_art/art_lighthouse.png";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";




function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "350px",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  paper: {
    position: "absolute",
    width: 800,
    maxHeight: 600,
    overflowY: "scroll",

    background: "hsl(210, 31%, 80%)",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: 450,
    "@media (max-width: 800px)": {
      width: 600,

      overflowY: "scroll",
    },
    "@media (max-width: 600px)": { width: 400, height: 400 },
    "@media (max-width: 360px)": { width: 275, height: 400 },
  },
  header: {
    fontFamily: "Cinzel, serif",
    fontSize: "0.8rem",
    fontWeight: "bold",

    color: "#222",
    marginBottom: 0,
    "@media (max-width:600px)": {
      marginLeft: 10,
    },
    "@media (max-width:460px)": {
      fontSize: "0.6rem",
    },
    "@media (max-width:360px)": {
      marginLeft: 0,
    },
    "@media (max-width:290px)": {
      fontSize: "0.6rem",
    },
  },
  avatar: {
    "@media (min-width:600px)": {
      display: "none",
    },
    "@media (max-width:360px)": {
      display: "none",
    },
  },
  button: {
    fontFamily: "Cinzel, serif",
    fontSize: "0.6rem",
    fontWeight: "bold",
    marginTop: 10,
    "@media (max-width:600px)": {
      marginTop: 0,
    },
    "@media (max-width:460px)": {
      display: "none",
    },
  },
  buttonMobile: {
    fontFamily: "Cinzel, serif",

    fontWeight: "bold",
    background: "none",
    outline: "none",
    "&:focus": {
      outline: "none",
      color: "hsl(125, 67%, 44%)",
    },
    "@media (min-width:460px)": {
      display: "none",
    },
  },
  action: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignContent: "center",
    background: "hsl(210, 36%, 96%)",
    "@media (max-width:600px)": {
      flexDirection: "row",
    },
    "@media (max-width:360px)": {
      justifyContent: "space-between",
    },
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    borderBottom: "1px solid #222",
    "@media (max-width: 600px)": { display: "none" },
  },
  display: {
    display: "flex",
    justifyContent: "center",
    margin: "20px",
    "@media (max-width: 600px)": {
      margin: "10px",
    },
  },
  img: {
    width: "90%",
  },
  grid: {
    backgroundColor: "black",
    marginTop: 10,
  },
  modalHeader: {
    padding: "10px 0px",
    fontFamily: "Cinzel, serif",
    fontSize: "1rem",
    color: "#fff",
    "@media (max-width: 600px)": { fontSize: "0.6rem" },
  },
  paperHeader: {
    background: "hsl(205, 86%, 17%)",

    display: "flex",
    justifyContent: "center",
  },
}));

const Card2 = () => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Grid container spacing={2} justify="center" alignContent="center">
        <Grid item xs={12}>
          <Paper elevation={3} className={classes.paperHeader}>
            <Typography className={classes.modalHeader} variant="body2">
              Glasgow architecture
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} className={classes.paperHeader}>
            <Typography className={classes.modalHeader} variant="body2">
              Adobe Photoshop / Mixed Media
            </Typography>
          </Paper>
        </Grid>
        <Grid item className={classes.grid}>
          <img className={classes.img} src={img1} alt="glasgow art" />
        </Grid>
        <Grid item className={classes.grid}>
          <img className={classes.img} src={img2} alt="glasgow art" />
        </Grid>
        <Grid item className={classes.grid}>
          <img className={classes.img} src={img3} alt="glasgow art" />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <>
      <div className={classes.display}>
        <Card className={classes.root}>
          <CardMedia
            className={classes.media}
            image={image1}
            title="Glasgow architecture"
            style={{ borderBottom: "1px solid #222" }}
          />

          <CardActions disableSpacing className={classes.action}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                className={classes.avatar}
                alt="Glasgow architecture"
                src={image1}
              />
              <Typography className={classes.header}>
                Glasgow architecture
              </Typography>
            </div>

            <button
              className={classes.buttonMobile}
              type="button"
              onClick={handleOpen}
              style={{
                outline: "none",
                border: "none",
                "&:focus": {
                  outline: "none",
                  background: "hsl(125, 67%, 44%)",
                },
              }}
            >
              <AddToQueueIcon
                color="secondary"
                style={{
                  "&:focus": {
                    color: "hsl(125, 67%, 44%)",
                  },
                }}
              />
            </button>
            <Button
              className={classes.button}
              size="small"
              variant="contained"
              color="secondary"
              type="button"
              onClick={handleOpen}
            >
              Show more
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="Glasgow architecture"
              aria-describedby="Glasgow architecture"
            >
              {body}
            </Modal>
          </CardActions>
        </Card>
      </div>
    </>
  );
};

export default Card2;
