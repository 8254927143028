import React from "react";
import Card13 from "./Card13";
import Card14 from "./Card14";
import Card15 from "./Card15";

import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
  },
}));

const Page3 = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={0} className={classes.root}>
        <Grid item xs={12} sm={6} lg={4}>
          <Card13 />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Card14 />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Card15 />
        </Grid>
      </Grid>
    </>
  );
};

export default Page3;
