import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, Grid, Paper } from "@material-ui/core";
import img1 from "./graphic_pages/assets/diploma/HND_Visual.jpg";
import img2 from "./graphic_pages/assets/diploma/HND_Web.jpg";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import StarIcon from "@material-ui/icons/Star";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import CheckIcon from "@material-ui/icons/Check";




const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 50,
    background: "hsl(205, 63%, 48%)",
    height: 600,
    overflowY: "scroll",
    overflowX: "hidden",
    padding: "20px 0px",
    
  },

  heading: {
    fontSize: "1.2rem",
    fontFamily: "Cinzel, serif",
    lineHeight: "1.5em",

    "@media (max-width:800px)": {
      fontSize: "1rem",
    },
    "@media (max-width:600px)": {
      fontSize: "0.8rem",
    },
  },
  text: {
    fontFamily: "Oswald, serif",
    fontSize: "1rem",
    lineHeight: "1.5em",
    "@media (max-width:600px)": {
      fontSize: "0.8rem",
    },
  },
  img: {
    width: "100%",
    "@media (max-width:1280px)": {
      display: "none",
    },
  },
  stars: {
    color: "rgba(0,127,255,1)",
    marginLeft: 5,
    fontSize: "small",
  },
  type: {
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid hsl(210, 31%, 80%)",
    padding: 5,
  },
}));

const Home = () => {

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Container maxWidth="lg">
        <Paper className={classes.paper}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={11}>
              <Typography className={classes.heading} style={{ color: "#fff" }}>
                Welcome to my portfolio
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>About me</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid direction="row" spacing={2} container>
                    <Grid item xs={12}>
                      <Typography className={classes.text}>
                        I have been living in Scotland since 2007. During these
                        few years, I graduated from{" "}
                        <strong>Graphic Design at Reid Keer College</strong> and{" "}
                        <strong>
                          Digital Design and Development at City of Glasgow
                          College
                        </strong>{" "}
                        . I have always been interested in graphics and
                        programming, so I decided to gain and expand my
                        knowledge.
                      </Typography>
                      <Typography
                        className={classes.text}
                        style={{ marginTop: 15 }}
                      >
                        I am currently studying React JS and running my own
                        online store. Studying in Scotland prepared me to work
                        as a graphic designer and front-end developer. I gained
                        knowledge about website programming{" "}
                        <strong>
                          (including JavaScript, JQuery, Bootstrap, PHP, MySQL)
                        </strong>
                        , creating Video materials{" "}
                        <strong>
                          (Adobe Premiere Pro / Adobe AfterEffect)
                        </strong>
                        , photo processing <strong>(Adobe Photoshop)</strong>{" "}
                        and vector graphics <strong>(Adobe Illustrator)</strong>
                        .
                      </Typography>
                      <Typography
                        className={classes.text}
                        style={{ marginTop: 15 }}
                      >
                        I also have qualifications in the preparation of project
                        plans, cost estimates and reports{" "}
                        <strong>(Microsoft Project)</strong>, preparation of
                        test questionnaires, preparation of project
                        documentation, Moodboard and Wireframes design and
                        implementation of UX / UI standards.
                      </Typography>
                      <Typography
                        className={classes.text}
                        style={{ marginTop: 15 }}
                      >
                        I feel great in a group of people who pursue a set goal,
                        I can also work individually, where I will be perfect in
                        creating vector graphics, processing photo materials,
                        preparing responsive web projects. If you are interested
                        in someone who is not afraid of challenges, you've come
                        to the right place.
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={11}>
              <Typography className={classes.heading} style={{ color: "#fff" }}>
                Education:
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Higher National Diploma in Visual Communication
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid direction="row" spacing={2} container>
                    <Grid item lg={2}>
                      <img className={classes.img} src={img1} alt="diploma" />
                    </Grid>
                    <Grid item xs={12} lg={10}>
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                      >
                        <Grid item xs={12}>
                          <Typography
                            className={classes.heading}
                            style={{ fontWeight: "bold" }}
                          >
                            09.2009 - 06.2012
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            className={classes.heading}
                            style={{ color: "hsl(360, 67%, 44%)" }}
                          >
                            Reid Keer College
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container direction="row">
                            <Grid item>
                              <Typography
                                className={classes.text}
                                style={{ margin: "10px 0px 0px 20px" }}
                              >
                                <CheckIcon
                                  style={{
                                    fontSize: "small",
                                    color: "hsl(360, 67%, 44%)",
                                    marginRight: 5,
                                  }}
                                />
                                Preparation of leaflets, cards, business cards
                              </Typography>
                              <Typography
                                className={classes.text}
                                style={{ margin: "0px 0px 0px 20px" }}
                              >
                                <CheckIcon
                                  style={{
                                    fontSize: "small",
                                    color: "hsl(360, 67%, 44%)",
                                    marginRight: 5,
                                  }}
                                />
                                Preparing a newsletter in Adobe InDesign
                              </Typography>
                              <Typography
                                className={classes.text}
                                style={{ margin: "0px 0px 0px 20px" }}
                              >
                                <CheckIcon
                                  style={{
                                    fontSize: "small",
                                    color: "hsl(360, 67%, 44%)",
                                    marginRight: 5,
                                  }}
                                />
                                Performing simple animations in Adobe Flash
                              </Typography>
                              <Typography
                                className={classes.text}
                                style={{ margin: "0px 0px 0px 20px" }}
                              >
                                <CheckIcon
                                  style={{
                                    fontSize: "small",
                                    color: "hsl(360, 67%, 44%)",
                                    marginRight: 5,
                                  }}
                                />
                                Stop-motion animation in Adobe Photoshop
                              </Typography>
                              <Typography
                                className={classes.text}
                                style={{ margin: "0px 0px 0px 20px" }}
                              >
                                <CheckIcon
                                  style={{
                                    fontSize: "small",
                                    color: "hsl(360, 67%, 44%)",
                                    marginRight: 5,
                                  }}
                                />
                                Preparation of vector graphics and photo
                                processing
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={11}>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography className={classes.heading}>
                    Higher National Diploma in Digital Design and Development
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid direction="row" spacing={2} container>
                    <Grid item lg={2}>
                      <img className={classes.img} src={img2} alt="diploma" />
                    </Grid>
                    <Grid item xs={12} lg={10}>
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                      >
                        <Grid item xs={12}>
                          <Typography
                            className={classes.heading}
                            style={{ fontWeight: "bold" }}
                          >
                            09.2017 - 06.2019
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            className={classes.heading}
                            style={{ color: "hsl(360, 67%, 44%)" }}
                          >
                            City of Glasgow College
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container direction="row">
                            <Grid item>
                              <Typography
                                className={classes.text}
                                style={{ margin: "10px 0px 0px 20px" }}
                              >
                                <CheckIcon
                                  style={{
                                    fontSize: "small",
                                    color: "hsl(360, 67%, 44%)",
                                    marginRight: 5,
                                  }}
                                />
                                Preparation of website designs.
                              </Typography>
                              <Typography
                                className={classes.text}
                                style={{ margin: "0px 0px 0px 20px" }}
                              >
                                <CheckIcon
                                  style={{
                                    fontSize: "small",
                                    color: "hsl(360, 67%, 44%)",
                                    marginRight: 5,
                                  }}
                                />
                                Planning project - Microsoft Project.
                              </Typography>
                              <Typography
                                className={classes.text}
                                style={{ margin: "0px 0px 0px 20px" }}
                              >
                                <CheckIcon
                                  style={{
                                    fontSize: "small",
                                    color: "hsl(360, 67%, 44%)",
                                    marginRight: 5,
                                  }}
                                />
                                Preparing documentation for the project (Test
                                Log's, Test plan, Requirements, Design
                                specification, Wireframes, Evaluation).
                              </Typography>

                              <Typography
                                className={classes.text}
                                style={{ margin: "0px 0px 0px 20px" }}
                              >
                                <CheckIcon
                                  style={{
                                    fontSize: "small",
                                    color: "hsl(360, 67%, 44%)",
                                    marginRight: 5,
                                  }}
                                />
                                Construction of websites using HTML and CSS,
                                MySQL databases, PHP, JavaScript, JQuery, React.
                              </Typography>
                              <Typography
                                className={classes.text}
                                style={{ margin: "0px 0px 0px 20px" }}
                              >
                                <CheckIcon
                                  style={{
                                    fontSize: "small",
                                    color: "hsl(360, 67%, 44%)",
                                    marginRight: 5,
                                  }}
                                />
                                Responsive web design Flex, Grid, Bootstrap,
                                Material UI
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={11}>
              <Typography className={classes.heading} style={{ color: "#fff" }}>
                Knowledge:
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                >
                  <Typography className={classes.heading}>
                    Programming
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} md={4}>
                          <Grid
                            container
                            direction="row"
                            className={classes.type}
                          >
                            <Grid item>
                              <Typography className={classes.text}>
                                HTML/CSS
                              </Typography>
                            </Grid>
                            <Grid item>
                              <StarIcon className={classes.stars} />
                              <StarIcon className={classes.stars} />
                              <StarIcon className={classes.stars} />
                              <StarIcon className={classes.stars} />
                              <StarOutlineIcon className={classes.stars} />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Grid
                            container
                            direction="row"
                            className={classes.type}
                          >
                            <Grid item>
                              <Typography className={classes.text}>
                                Javascript
                              </Typography>
                            </Grid>
                            <Grid item>
                              <StarIcon className={classes.stars} />
                              <StarIcon className={classes.stars} />
                              <StarOutlineIcon className={classes.stars} />
                              <StarOutlineIcon className={classes.stars} />
                              <StarOutlineIcon className={classes.stars} />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Grid
                            container
                            direction="row"
                            className={classes.type}
                          >
                            <Grid item>
                              <Typography className={classes.text}>
                                React JS
                              </Typography>
                            </Grid>
                            <Grid item>
                              <StarIcon className={classes.stars} />
                              <StarIcon className={classes.stars} />
                              <StarOutlineIcon className={classes.stars} />
                              <StarOutlineIcon className={classes.stars} />
                              <StarOutlineIcon className={classes.stars} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} md={4}>
                          <Grid
                            container
                            direction="row"
                            className={classes.type}
                          >
                            <Grid item>
                              <Typography className={classes.text}>
                                Material UI
                              </Typography>
                            </Grid>
                            <Grid item>
                              <StarIcon className={classes.stars} />
                              <StarIcon className={classes.stars} />
                              <StarIcon className={classes.stars} />
                              <StarOutlineIcon className={classes.stars} />
                              <StarOutlineIcon className={classes.stars} />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Grid
                            container
                            direction="row"
                            className={classes.type}
                          >
                            <Grid item>
                              <Typography className={classes.text}>
                                Styled components
                              </Typography>
                            </Grid>
                            <Grid item>
                              <StarIcon className={classes.stars} />
                              <StarIcon className={classes.stars} />
                              <StarIcon className={classes.stars} />
                              <StarOutlineIcon className={classes.stars} />
                              <StarOutlineIcon className={classes.stars} />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Grid
                            container
                            direction="row"
                            className={classes.type}
                          >
                            <Grid item>
                              <Typography className={classes.text}>
                                JQuery
                              </Typography>
                            </Grid>
                            <Grid item>
                              <StarIcon className={classes.stars} />
                              <StarIcon className={classes.stars} />
                              <StarOutlineIcon className={classes.stars} />
                              <StarOutlineIcon className={classes.stars} />
                              <StarOutlineIcon className={classes.stars} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={11}>
              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5a-content"
                  id="panel5a-header"
                >
                  <Typography className={classes.heading}>
                    Graphic Design
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} md={4}>
                          <Grid
                            container
                            direction="row"
                            className={classes.type}
                          >
                            <Grid item>
                              <Typography className={classes.text}>
                                Adobe Photoshop
                              </Typography>
                            </Grid>
                            <Grid item>
                              <StarIcon className={classes.stars} />
                              <StarIcon className={classes.stars} />
                              <StarIcon className={classes.stars} />
                              <StarIcon className={classes.stars} />
                              <StarOutlineIcon className={classes.stars} />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Grid
                            container
                            direction="row"
                            className={classes.type}
                          >
                            <Grid item>
                              <Typography className={classes.text}>
                                Adobe Illustrator
                              </Typography>
                            </Grid>
                            <Grid item>
                              <StarIcon className={classes.stars} />
                              <StarIcon className={classes.stars} />
                              <StarIcon className={classes.stars} />
                              <StarIcon className={classes.stars} />
                              <StarOutlineIcon className={classes.stars} />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Grid
                            container
                            direction="row"
                            className={classes.type}
                          >
                            <Grid item>
                              <Typography className={classes.text}>
                                Adobe InDesign
                              </Typography>
                            </Grid>
                            <Grid item>
                              <StarIcon className={classes.stars} />
                              <StarIcon className={classes.stars} />
                              <StarOutlineIcon className={classes.stars} />
                              <StarOutlineIcon className={classes.stars} />
                              <StarOutlineIcon className={classes.stars} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default Home;
