import React from 'react';
import Card1 from './Card1';
import Card2 from "./Card2";
import Card3 from "./Card3";
import Card4 from "./Card4";
import Card5 from "./Card5";
import Card6 from "./Card6";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    

  }

}))

 const Page1 = () => {
   const classes = useStyles();

    return (
      <>
        <Grid container className={classes.root}>
          <Grid item xs={12} sm={6} lg={4}>
            <Card1 />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <Card2 />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <Card3 />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Card4 />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <Card5 />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Card6 />
          </Grid>
        </Grid>
      </>
    );
}

export default Page1;