import React from "react";
import image1 from "./assets/math/home_page.png";
import { makeStyles } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import img1 from "./assets/math/site_map_sm.png";
import img2 from "./assets/math/home_page.png";
import img3 from "./assets/math/home_page_contact.png";
import img4 from "./assets/math/home_page_developer.png";
import img5 from "./assets/math/content_page.png";
import img6 from "./assets/math/content_page_show1.png";
import img7 from "./assets/math/content_page_show2.png";
import img8 from "./assets/math/math_game.png";
import img9 from "./assets/math/math_game_correct.png";
import img10 from "./assets/math/math_game_wrong.png";
import img11 from "./assets/math/math_game_video.png";
import img12 from "./assets/math/numbers_game.png";
import img13 from "./assets/math/numbers_game_move.png";
import img14 from "./assets/math/parents_page.png";
import img15 from "./assets/math/parents_page_1.png";
import img16 from "./assets/math/parents_page_2.png";
import img17 from "./assets/math/parents_page_3.png";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AboutProjectAccordion from "./card2components/MathAboutProjectAccordion";
import RequirementsAccordion from "./card2components/RequirementsAccordion";
import PlanningAccordion from "./card2components/PlanningAccordion";


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "350px",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  paper: {
    position: "absolute",
    width: 800,
    height: 600,
    overflowY: "scroll",

    background: "hsl(210, 31%, 80%)",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "@media (max-width: 800px)": {
      width: 600,
      height: 600,
      overflowY: "scroll",
    },
    "@media (max-width: 600px)": { width: 400, height: 400 },
    "@media (max-width: 360px)": { width: 275, height: 400 },
  },
  header: {
    fontFamily: "Cinzel, serif",
    fontSize: "0.8rem",
    fontWeight: "bold",

    color: "#222",
    marginBottom: 0,
    "@media (max-width:600px)": {
      marginLeft: 10,
    },
    "@media (max-width:460px)": {
      fontSize: "0.6rem",
    },
    "@media (max-width:360px)": {
      marginLeft: 0,
    },
    "@media (max-width:290px)": {
      fontSize: "0.6rem",
    },
  },
  avatar: {
    "@media (min-width:600px)": {
      display: "none",
    },
    "@media (max-width:360px)": {
      display: "none",
    },
  },
  button: {
    fontFamily: "Cinzel, serif",
    fontSize: "0.6rem",
    fontWeight: "bold",
    marginTop: 10,
    "@media (max-width:600px)": {
      marginTop: 0,
    },
    "@media (max-width:460px)": {
      display: "none",
    },
  },
  buttonMobile: {
    fontFamily: "Cinzel, serif",

    fontWeight: "bold",
    background: "none",
    outline: "none",
    "&:focus": {
      outline: "none",
      color: "hsl(125, 67%, 44%)",
    },
    "@media (min-width:460px)": {
      display: "none",
    },
  },
  action: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignContent: "center",
    background: "hsl(210, 36%, 96%)",
    "@media (max-width:600px)": {
      flexDirection: "row",
    },
    "@media (max-width:360px)": {
      justifyContent: "space-between",
    },
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    borderBottom: "1px solid #222",
    "@media (max-width: 600px)": { display: "none" },
  },
  display: {
    display: "flex",
    justifyContent: "center",
    margin: "20px",
    "@media (max-width: 600px)": {
      margin: "10px",
    },
  },
  grid: {
    border: "1px solid black",
    background: "#fff",
    margin: 5,
    marginTop: 10,
  },
  img: {
    width: "100%",
  },
  modalHeader: {
    padding: "10px 0px",
    fontFamily: "Cinzel, serif",
    fontSize: "1rem",
    color: "#fff",
    "@media (max-width: 600px)": { fontSize: "0.6rem" },
  },
  paperHeader: {
    background: "hsl(205, 86%, 17%)",

    display: "flex",
    justifyContent: "center",
  },
  type: {
    justifyContent: "flex-end",
    alignItems: "center",
  },
  text: {
    fontFamily: "Oswald, serif",
    fontSize: "1rem",
    margin: "10px 0px",
    padding: 5,
    borderBottom: "1px solid hsl(360, 67%, 44%)",
    "@media (max-width:600px)": {
      fontSize: "0.8rem",
    },
  },
  heading: {
    fontSize: "1.2rem",
    fontFamily: "Cinzel, serif",
    lineHeight: "1.5em",

    "@media (max-width:800px)": {
      fontSize: "1rem",
    },
    "@media (max-width:600px)": {
      fontSize: "0.8rem",
    },
  },
  showMeButton: {
    padding: "5px 15px",
    borderRadius: 5,
    margin: 10,
    background: "rgba(187, 37, 37, 0.8)",
    border: "1px solid hsl(360, 67%, 44%)",
    color: "white",
    fontFamily: "Oswald, serif",
    fontSize: "0.8rem",
    "&:hover": {
      color: "white",
      background: "hsl(0, 100%, 41.76%)",
    },
    "@media (max-width:600px)": {
      fontSize: "0.6rem",
    },
  },
}));

const Card2 = () => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Grid container spacing={1} justify="center" alignContent="center">
        <Grid item xs={12}>
          <Paper elevation={3} className={classes.paperHeader}>
            <Grid container justifyContent="center">
              <Grid item>
                <Typography className={classes.modalHeader} variant="body2">
                  The Numeracy and Maths
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} className={classes.paperHeader}>
            <Typography className={classes.modalHeader} variant="body2">
              HTML / CSS
            </Typography>
          </Paper>
        </Grid>
        <Grid item className={classes.modalHeader} style={{margin: '10px 0px'}}>
          <Typography>
            <a
              href="https://math.webdev.smarthost.pl/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.showMeButton}
              
            >
              Show me this project
            </a>
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>About project</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AboutProjectAccordion />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={11}>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>Site map</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                direction="row"
                spacing={2}
                justifyContent="center"
                container
              >
                <Grid item xs={12}>
                  <img className={classes.img} src={img1} alt="site map" />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={11}>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography className={classes.heading}>Requirements</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <RequirementsAccordion />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={11}>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography className={classes.heading}>Planning</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PlanningAccordion />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={11}>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5a-content"
              id="panel5a-header"
            >
              <Typography className={classes.heading}>
                Project view Home page
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                direction="row"
                spacing={2}
                justifyContent="center"
                container
              >
                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className={classes.text}>
                        Home page
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img className={classes.img} src={img2} alt="Home" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className={classes.text}>
                        Home page contact
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img className={classes.img} src={img3} alt="Home" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className={classes.text}>
                        Home page developer
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img className={classes.img} src={img4} alt="Home" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={11}>
          <Accordion
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7a-content"
              id="panel7a-header"
            >
              <Typography className={classes.heading}>
                Project view - Content page
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                direction="row"
                spacing={2}
                justifyContent="center"
                container
              >
                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className={classes.text}>
                        Content page
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img className={classes.img} src={img5} alt="Content" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className={classes.text}>
                        Content page show 1
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img className={classes.img} src={img6} alt="Content" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className={classes.text}>
                        Content page show 2
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img className={classes.img} src={img7} alt="Content" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={11}>
          <Accordion
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel8a-content"
              id="panel8a-header"
            >
              <Typography className={classes.heading}>
                Project view - Math game page
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                direction="row"
                spacing={2}
                justifyContent="center"
                container
              >
                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className={classes.text}>
                        Math game page
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img className={classes.img} src={img8} alt="Math" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className={classes.text}>
                        Math game page correct answer
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img className={classes.img} src={img9} alt="Math" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className={classes.text}>
                        Math game page wrong answer
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img className={classes.img} src={img10} alt="Math" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className={classes.text}>
                        Math game page video
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img className={classes.img} src={img11} alt="Math" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={11}>
          <Accordion
            expanded={expanded === "panel9"}
            onChange={handleChange("panel9")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel9a-content"
              id="panel9a-header"
            >
              <Typography className={classes.heading}>
                Project view - Numbers game
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                direction="row"
                spacing={2}
                justifyContent="center"
                container
              >
                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className={classes.text}>
                        Numbers game page
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img className={classes.img} src={img12} alt="Numbers" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className={classes.text}>
                        Numbers game page move
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img className={classes.img} src={img13} alt="Numbers" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={11}>
          <Accordion
            expanded={expanded === "panel10"}
            onChange={handleChange("panel10")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel10a-content"
              id="panel10a-header"
            >
              <Typography className={classes.heading}>
                Project view - Parents page
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                direction="row"
                spacing={2}
                justifyContent="center"
                container
              >
                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className={classes.text}>
                        Parents page
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img className={classes.img} src={img14} alt="Parents" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className={classes.text}>
                        Parents page show 1
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img className={classes.img} src={img15} alt="Parents" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className={classes.text}>
                        Parents page show 2
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img className={classes.img} src={img16} alt="Parents" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className={classes.text}>
                        Parents page show 3
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img className={classes.img} src={img17} alt="Parents" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <>
      <div className={classes.display}>
        <Card className={classes.root}>
          <CardMedia
            className={classes.media}
            image={image1}
            title="Numeracy and Maths with Mr. Octi"
          />
          <CardActions disableSpacing className={classes.action}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                className={classes.avatar}
                alt="Numeracy and Maths with Mr. Octi"
                src={image1}
              />
              <Typography className={classes.header}>
                Numeracy and Maths 
              </Typography>
            </div>

            <button
              className={classes.buttonMobile}
              type="button"
              onClick={handleOpen}
              style={{
                outline: "none",
                border: "none",
                "&:focus": {
                  outline: "none",
                  background: "hsl(125, 67%, 44%)",
                },
              }}
            >
              <AddToQueueIcon
                color="secondary"
                style={{
                  "&:focus": {
                    color: "hsl(125, 67%, 44%)",
                  },
                }}
              />
            </button>
            <Button
              className={classes.button}
              size="small"
              variant="contained"
              color="secondary"
              type="button"
              onClick={handleOpen}
            >
              Show more
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="Numeracy and Maths with Mr. Octi"
              aria-describedby="Numeracy and Maths with Mr. Octi"
            >
              {body}
            </Modal>
          </CardActions>
        </Card>
      </div>
    </>
  );
};

export default Card2;
