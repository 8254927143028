import React from 'react';
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    marginLeft: 20,
    marginBottom: 10,
    fontFamily: "Oswald, serif",
    fontSize: "1rem",
    "@media (max-width:600px)": {
      fontSize: "0.8rem",
    },
  },
  text2: {
    marginLeft: 30,
    marginBottom: 10,
    fontFamily: "Oswald, serif",
    fontSize: "1rem",
    "@media (max-width:600px)": {
      fontSize: "0.8rem",
    },
  },
  heading: {
    fontSize: "1rem",
    fontFamily: "Cinzel, serif",
    marginBottom: 10,
    color: 'hsl(360, 67%, 44%)',

    "@media (max-width:800px)": {
      fontSize: "0.8rem",
    },
    "@media (max-width:600px)": {
      fontSize: "0.6rem",
    },
  },
}));

const RequirementsAccordion = () => {
  const classes = useStyles();
  return (
    <>
      <Grid direction="row" spacing={2} container>
        <Grid item xs={12}>
          <Typography className={classes.heading}>
            The website should contain a minimum of 5 pages.
          </Typography>

          <Typography className={classes.text}>
            To meet this requirement the school has suggested the following as a
            breakdown of pages to include:
          </Typography>
          <Typography className={classes.text2}>
            1. Index/Splash Page (Home)
          </Typography>
          <Typography className={classes.text2}>
            2. General Information /About Page (for end users Parents/Guardians){" "}
          </Typography>
          <Typography className={classes.text2}>
            3. Content Page(s) (instructional/revision material for interactive
            page)
          </Typography>
          <Typography className={classes.text2}>
            4. Interactive Page(s) (to test knowledge of curriculum area from
            content page)
          </Typography>
          <Typography className={classes.text}>
            NOTE: Your completed site must have a minimum of 5 pages. To achieve
            this you can choose to have either 2 content pages OR 2 interactive
            pages
          </Typography>

          <Typography className={classes.text}>
            Each of the pages outlined above will require specific
            content/features to be included which are detailed below:
          </Typography>
          <Typography className={classes.heading}>
            1. Index/Splash Page (Home)
          </Typography>
          <Typography className={classes.text}>
            a. The index/Splash page should contain a brief overview of the
            purpose of the site. This could be in the form of text or visually
            using an image marquee/carousel made up of specific images and
            straplines/callouts for each of the curriculum areas.
          </Typography>
          <Typography className={classes.text}>
            b. The page should also have functioning links to each page in the
            site.
          </Typography>
          <Typography className={classes.heading}>
            2. General Information / About Page (for Parents/Guardians)
          </Typography>
          <Typography className={classes.text}>
            a. The general information page should provide parents with
            information on how to encourage, support and provide additional
            education for their child at home. It should also provide an
            overview of the importance of the chosen curriculum area.
          </Typography>
          <Typography className={classes.text}>
            b. This page should also provide functionality to allow the user to
            sign-up for a monthly newsletter.
          </Typography>
          <Typography className={classes.heading}>
            3. Content Page(s) (study/revision material for interactive page)
          </Typography>
          <Typography className={classes.text}>
            a. The Content Page(s) should be aimed at the learner and must
            include instructional learning material relating to the chosen
            curriculum area. The learning material could be in the form of:
            text/images/graphics/audio/video/animation etc.
          </Typography>
          <Typography className={classes.text}>
            b. The instructional learning material should also be presented in a
            fun and engaging way that is appropriate for the end user/age group.
          </Typography>
          <Typography className={classes.text}>
            c. All of the content you choose to include on your site should be
            appropriate for your chosen curriculum area and also your identified
            target user/audience etc.
          </Typography>
          <Typography className={classes.text}>
            d. It is also important to pitch/level your content so that it is
            appropriate for the desired age group/ability
          </Typography>
          <Typography className={classes.heading}>
            4. Interactive Page(s) (test knowledge of curriculum area from
            content page)
          </Typography>
          <Typography className={classes.text}>
            a. The Interactive Page(s) should provide opportunity for the
            learner to test their knowledge about the curriculum area (as
            detailed in the content page) using a variety of user interactions.
            These could be in the form of a game/quiz and may include:
          </Typography>
          <Typography className={classes.text2}>I Drag and Drop</Typography>
          <Typography className={classes.text2}>II Sortable Lists</Typography>
          <Typography className={classes.text2}>III Slider Controls</Typography>
          <Typography className={classes.text2}>IV Quizes</Typography>
          <Typography className={classes.text}>
            b. Appropriate feedback (visual/textual/audio) relating to user
            progress must be included.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default RequirementsAccordion;
