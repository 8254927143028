import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: "Oswald, serif",
    fontSize: "1rem",
    margin: "10px 0px",
    "@media (max-width:600px)": {
      fontSize: "0.8rem",
    },
  },
}));

const AboutProjectAccordion = () => {

    const classes = useStyles();
    return (
      <>
        <Grid direction="row" spacing={2} container>
          <Grid item xs={12}>
            <Typography
              className={classes.text}
              style={{ color: "hsl(360, 67%, 44%)" }}
            >
              College project
            </Typography>
            <Typography className={classes.text}>
              For my client, the <strong>National Museum of Scotland</strong>, I
              decided to produce an interactive application in the form of an
              educational platform.
              <Typography
                className={classes.text}
              >
                When designing this application, I wanted to reach as many
                people as possible with the widest possible age spectrum. I
                tried to make the application design so that the ready product
                could be presented on as many available devices as possible. For
                this purpose, I decided to use the <strong>Bootstrap 4</strong>{" "}
                framework which provided my application with the ability to
                customize the displayed content to the current screen
                resolution. In this way, I was able to construct an application
                that can be successfully displayed on large screens of desktops
                through laptops, tablets up to mobile devices such as mobile
                phones.
              </Typography>
              <Typography
                className={classes.text}
              >
                I made the project with the smallest screen size in mind, which
                allowed me to focus better on valuable educational information
                than on visual effects.In this way, I provided the end user with
                access to valuable content with which they will be able to
                become familiar, for example during a visit to the museum.
              </Typography>
              <Typography
                className={classes.text}
                
              >
                The educational content is marked with titles consistent with
                the current content available in the museum. Thanks to this, the
                user will be able to read and, in some cases, listen to
                educational content using their own mobile devices.
              </Typography>
              <Typography
                className={classes.text}
                
              >
                I wanted my application to provide the user with as much
                information as possible about current exhibitions. I also wanted
                it to be so easy to use and understandable in use that everyone
                could use it regardless of age.
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </>
    );
}

export default AboutProjectAccordion;