import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: "Cinzel, serif",
    fontSize: "0.8rem",
    color: "hsl(205, 90%, 76%)",
  },
}));
const Footer = () => {

    const classes = useStyles();

    return (
      <>
        <Paper
          style={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            background: "hsl(205, 72%, 37%)",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignContent="center"
            style={{ height: 50, width: "100%" }}
          >
            <Grid item>
              <Typography className={classes.text}>
                Copyright Zbigniew Wyziński 2021
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
}
export default Footer;