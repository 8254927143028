import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import img1 from "./assets/hunterian/Quiz page PNG moodboard/PC 1920 x 1080 px Quiz.jpg";
import img2 from "./assets/hunterian/Quiz page PNG moodboard/Slide 1.jpg";
import img3 from "./assets/hunterian/Quiz page PNG moodboard/Slide 2.jpg";
import img4 from "./assets/hunterian/Quiz page PNG moodboard/Slide 3.jpg";
import img5 from "./assets/hunterian/Quiz page PNG moodboard/Slide 4.jpg";
import img6 from "./assets/hunterian/Quiz page PNG moodboard/Slide 5.jpg";
import img7 from "./assets/hunterian/Quiz page PNG moodboard/Slide 6.jpg";
import img8 from "./assets/hunterian/Quiz page PNG moodboard/PC 1920 x 1080 px Quiz-1.jpg";
import img9 from "./assets/hunterian/Quiz page PNG moodboard/Slide 1  Minerals.jpg";
import img10 from "./assets/hunterian/Quiz page PNG moodboard/Slide 2 Minerals.jpg";
import img11 from "./assets/hunterian/Quiz page PNG moodboard/Slide 3 Minerals.jpg";
import img12 from "./assets/hunterian/Quiz page PNG moodboard/Slide 4 Minerals.jpg";
import img13 from "./assets/hunterian/Quiz page PNG moodboard/Slide 5 Minerals.jpg";
import img14 from "./assets/hunterian/Quiz page PNG moodboard/Slide 6 Minerals.jpg";

const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
  },
  text: {
    fontFamily: "Oswald, serif",
    fontSize: "1rem",
    margin: "10px 0px",
    padding: 5,
    borderBottom: "1px solid hsl(360, 67%, 44%)",
    "@media (max-width:600px)": {
      fontSize: "0.8rem",
    },
  },
  heading: {
    fontSize: "1rem",
    fontFamily: "Cinzel, serif",

    "@media (max-width:800px)": {
      fontSize: "0.8rem",
    },
    "@media (max-width:600px)": {
      fontSize: "0.6rem",
    },
  },
}));

const QuizAccordion = () => {

    const classes = useStyles();

    return (
        <>
            <Grid container direction="row" spacing={2} justifyContent="center">
                <Grid item xs={12}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Typography className={classes.text}>
                                Quiz page - Art Gallery Quiz
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img
                                className={classes.img}
                                src={img1}
                                alt="Quiz page - Art Gallery Quiz"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Typography className={classes.text}>
                                Quiz page - Art Gallery Quiz - Question 1
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img
                                className={classes.img}
                                src={img2}
                                alt="Quiz page - Art Gallery Quiz - Question 1"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Typography className={classes.text}>
                                Quiz page - Art Gallery Quiz - Question 2
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img
                                className={classes.img}
                                src={img3}
                                alt="Quiz page - Art Gallery Quiz - Question 2"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Typography className={classes.text}>
                                Quiz page - Art Gallery Quiz - Question 3
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img
                                className={classes.img}
                                src={img4}
                                alt="Quiz page - Art Gallery Quiz - Question 3"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Typography className={classes.text}>
                                Quiz page - Art Gallery Quiz - Question 4
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img
                                className={classes.img}
                                src={img5}
                                alt="Quiz page - Art Gallery Quiz - Question 4"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Typography className={classes.text}>
                                Quiz page - Art Gallery Quiz - Question 5
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img
                                className={classes.img}
                                src={img6}
                                alt="Quiz page - Art Gallery Quiz - Question 5"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Typography className={classes.text}>
                                Quiz page - Art Gallery Quiz - Result
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img
                                className={classes.img}
                                src={img7}
                                alt="Quiz page - Art Gallery Quiz - Result"
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Typography className={classes.text}>
                                Quiz page - Minerals Quiz
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img
                                className={classes.img}
                                src={img8}
                                alt="Quiz page - Minerals Quiz"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Typography className={classes.text}>
                                Quiz page - Minerals Quiz - Question 1
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img
                                className={classes.img}
                                src={img9}
                                alt="Quiz page - Minerals Quiz - Question 1"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Typography className={classes.text}>
                                Quiz page - Minerals Quiz - Question 2
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img
                                className={classes.img}
                                src={img10}
                                alt="Quiz page - Minerals Quiz - Question 2"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Typography className={classes.text}>
                                Quiz page - Minerals Quiz - Question 3
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img
                                className={classes.img}
                                src={img11}
                                alt="Quiz page - Minerals Quiz - Question 3"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Typography className={classes.text}>
                                Quiz page - Minerals Quiz - Question 4
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img
                                className={classes.img}
                                src={img12}
                                alt="Quiz page - Minerals Quiz - Question 4"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Typography className={classes.text}>
                                Quiz page - Minerals Quiz - Question 5
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img
                                className={classes.img}
                                src={img13}
                                alt="Quiz page - Minerals Quiz - Question 5"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Typography className={classes.text}>
                                Quiz page - Minerals Quiz - Result
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img
                                className={classes.img}
                                src={img14}
                                alt="Quiz page - Minerals Quiz - Result"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default QuizAccordion;