import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import img1 from "./assets/hunterian/Art Gallery PNG moodboard/PC 1920 x 1080 px Art Art Gallery.jpg";
import img2 from "./assets/hunterian/Art Gallery PNG moodboard/Scroll menu (Horizontal).jpg";
import img3 from "./assets/hunterian/Art Gallery PNG moodboard/Slide 1.jpg";
import img4 from "./assets/hunterian/Art Gallery PNG moodboard/Slide 2.jpg";
import img5 from "./assets/hunterian/Art Gallery PNG moodboard/Slide 3.jpg";
import img6 from "./assets/hunterian/Art Gallery PNG moodboard/Slide 4.jpg";
import img7 from "./assets/hunterian/Art Gallery PNG moodboard/Slide 5.jpg";
import img8 from "./assets/hunterian/Art Gallery PNG moodboard/Slide 6.jpg";
import img9 from "./assets/hunterian/Art Gallery PNG moodboard/Slide 7.jpg";
import img10 from "./assets/hunterian/Art Gallery PNG moodboard/Slide 8.jpg";
import img11 from "./assets/hunterian/Art Gallery PNG moodboard/Slide 9.jpg";
import img12 from "./assets/hunterian/Art Gallery PNG moodboard/Slide 10.jpg";
import img13 from "./assets/hunterian/Art Gallery PNG moodboard/Slide 11.jpg";
import img14 from "./assets/hunterian/Art Gallery PNG moodboard/Slide 12.jpg";
import img15 from "./assets/hunterian/Art Gallery PNG moodboard/PC 1920 x 1080 px Art German Revolution.jpg";
import img16 from "./assets/hunterian/Art Gallery PNG moodboard/About Exhibit.jpg";

const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
  },
  text: {
    fontFamily: "Oswald, serif",
    fontSize: "1rem",
    margin: "10px 0px",
    padding: 5,
borderBottom: '1px solid hsl(360, 67%, 44%)',
    "@media (max-width:600px)": {
      fontSize: "0.8rem",
    },
  },
  heading: {
    fontSize: "1rem",
    fontFamily: "Cinzel, serif",

    "@media (max-width:800px)": {
      fontSize: "0.8rem",
    },
    "@media (max-width:600px)": {
      fontSize: "0.6rem",
    },
  },
}));

const ArtGalleryAccordion = () =>{

    const classes = useStyles();

    return (
      <>
        <Grid container direction="row" spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Art Gallery page - Art Gallery
                </Typography>
              </Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={img1}
                  alt="Art Gallery page - Art Gallery"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Art Gallery page - Art Gallery - Scroll
                </Typography>
              </Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={img2}
                  alt="Art Gallery page - Art Gallery - Scroll"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Art Gallery page - Art Gallery - Slide 1
                </Typography>
              </Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={img3}
                  alt="Art Gallery page - Art Gallery - Slide 1"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Art Gallery page - Art Gallery - Slide 2
                </Typography>
              </Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={img4}
                  alt="Art Gallery page - Art Gallery - Slide 2"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Art Gallery page - Art Gallery - Slide 3
                </Typography>
              </Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={img5}
                  alt="Art Gallery page - Art Gallery - Slide 3"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Art Gallery page - Art Gallery - Slide 4
                </Typography>
              </Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={img6}
                  alt="Art Gallery page - Art Gallery - Slide 4"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Art Gallery page - Art Gallery - Slide 5
                </Typography>
              </Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={img7}
                  alt="Art Gallery page - Art Gallery - Slide 5"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Art Gallery page - Art Gallery - Slide 6
                </Typography>
              </Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={img8}
                  alt="Art Gallery page - Art Gallery - Slide 6"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Art Gallery page - Art Gallery - Slide 7
                </Typography>
              </Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={img9}
                  alt="Art Gallery page - Art Gallery - Slide 7"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Art Gallery page - Art Gallery - Slide 8
                </Typography>
              </Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={img10}
                  alt="Art Gallery page - Art Gallery - Slide 8"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Art Gallery page - Art Gallery - Slide 9
                </Typography>
              </Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={img11}
                  alt="Art Gallery page - Art Gallery - Slide 9"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Art Gallery page - Art Gallery - Slide 10
                </Typography>
              </Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={img12}
                  alt="Art Gallery page - Art Gallery - Slide 10"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Art Gallery page - Art Gallery - Slide 11
                </Typography>
              </Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={img13}
                  alt="Art Gallery page - Art Gallery - Slide 11"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Art Gallery page - Art Gallery - Slide 12
                </Typography>
              </Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={img14}
                  alt="Art Gallery page - Art Gallery - Slide 12"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Art Gallery page - German Revolution
                </Typography>
              </Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={img15}
                  alt="Art Gallery page - German Revolution"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.text}>
                  Art Gallery page - German Revolution - Board
                </Typography>
              </Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={img16}
                  alt="Art Gallery page - German Revolution - Board"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
}

export default ArtGalleryAccordion;